import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title="Eğitmenler" />
      <h1>Egitmenler</h1>
      <section className="bg-half bg-light">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <Link to='/egitmenler'>
                &larr; Geri
              </Link>
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <div className="page-next-level">
                    <h1> {post.frontmatter.title}</h1>
                    <ul className="list-unstyled mt-4">
                      <li className="list-inline-item h6 user text-muted mr-2">
                        <i className="mdi mdi-account" />
                      </li>
                      <li className="list-inline-item h6 date text-muted">
                        <i className="mdi mdi-calendar-check" />{" "}
                        {post.frontmatter.position}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="mr-lg-6">
                <div className="blog position-relative overflow-hidden shadow rounded">
                  <div className="position-relative">
                    {post.frontmatter.avatarImage ? (
                      <img
                        src={post.frontmatter.avatarImage}
                        style={{
                          borderRadius:"3px"
                        }}
                        className="img-fluid d-block mx-auto mentor-image-detail"
                        alt={post.frontmatter.title}
                      />
                    ) : null}
                  </div>
                  <div className="content p-4">
                    <p className="text-muted mt-3">
                      <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    </p>
                    <div className="post-meta mt-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        position
       avatarImage 
      }
    }
  }
`
